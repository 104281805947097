define("discourse/plugins/discourse-encrypt/discourse/templates/connectors/composer-action-after/encrypt", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showEncryptControls}}
    <div class="encrypt-controls">
      {{#if this.isEncryptActive}}
        <a
          href
          {{action "clicked"}}
          title={{this.title}}
          class="btn btn-default btn-icon no-text encrypt-toggle
            {{if this.model.isEncrypted 'enabled'}}
            {{if this.disabled 'disabled'}}"
        >
          {{d-icon (if this.model.isEncrypted "lock" "unlock")}}
        </a>
  
        {{~#if this.model.isEncrypted}}
          {{~#unless this.model.editingPost~}}
            <div
              class="composer-action-divider"
            ></div>{{! inline to avoid whitespace}}<EncryptedPostTimerDropdown
              @topicDeleteAt={{this.model.topic.delete_at}}
              @onChange={{action "timerClicked"}}
            />
  
            {{#if this.model.deleteAfterMinutesLabel}}
              <span
                title={{i18n
                  "encrypt.time_bomb.title"
                  after=this.model.deleteAfterMinutesLabel
                }}
              >
                {{this.model.deleteAfterMinutesLabel}}
              </span>
            {{/if}}
          {{/unless}}
        {{/if}}
  
        {{#if this.model.showEncryptError}}
          <span class="error">{{this.model.encryptError}}</span>
        {{/if}}
      {{else if this.isEncryptEnabled}}
        <span title={{i18n "encrypt.preferences.status_enabled_but_inactive"}}>
          {{d-icon "unlock" class="disabled"}}
        </span>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "G6C/vxiu",
    "block": "[[[41,[30,0,[\"showEncryptControls\"]],[[[1,\"  \"],[10,0],[14,0,\"encrypt-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"isEncryptActive\"]],[[[1,\"      \"],[11,3],[24,6,\"\"],[16,\"title\",[30,0,[\"title\"]]],[16,0,[29,[\"btn btn-default btn-icon no-text encrypt-toggle\\n          \",[52,[30,0,[\"model\",\"isEncrypted\"]],\"enabled\"],\"\\n          \",[52,[30,0,[\"disabled\"]],\"disabled\"]]]],[4,[38,1],[[30,0],\"clicked\"],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[[52,[30,0,[\"model\",\"isEncrypted\"]],\"lock\",\"unlock\"]],null]],[1,\"\\n      \"],[13],[41,[30,0,[\"model\",\"isEncrypted\"]],[[[41,[51,[30,0,[\"model\",\"editingPost\"]]],[[[10,0],[14,0,\"composer-action-divider\"],[12],[13],[8,[39,4],null,[[\"@topicDeleteAt\",\"@onChange\"],[[30,0,[\"model\",\"topic\",\"delete_at\"]],[28,[37,1],[[30,0],\"timerClicked\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"deleteAfterMinutesLabel\"]],[[[1,\"            \"],[10,1],[15,\"title\",[28,[37,5],[\"encrypt.time_bomb.title\"],[[\"after\"],[[30,0,[\"model\",\"deleteAfterMinutesLabel\"]]]]]],[12],[1,\"\\n              \"],[1,[30,0,[\"model\",\"deleteAfterMinutesLabel\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"showEncryptError\"]],[[[1,\"        \"],[10,1],[14,0,\"error\"],[12],[1,[30,0,[\"model\",\"encryptError\"]]],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"isEncryptEnabled\"]],[[[1,\"      \"],[10,1],[15,\"title\",[28,[37,5],[\"encrypt.preferences.status_enabled_but_inactive\"],null]],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"unlock\"],[[\"class\"],[\"disabled\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"action\",\"d-icon\",\"unless\",\"encrypted-post-timer-dropdown\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/templates/connectors/composer-action-after/encrypt.hbs",
    "isStrictMode": false
  });
});