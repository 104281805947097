define("discourse/plugins/discourse-encrypt/discourse/components/modal/activate-encrypt", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "discourse/plugins/discourse-encrypt/lib/discourse", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _discourse, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "encrypt.title"}}
    @closeModal={{this.close}}
    @flash={{this.error}}
    @flashType="error"
    class="activate-encrypt-modal"
  >
    <:body>
      <p>{{i18n "encrypt.preferences.status_enabled_but_inactive"}}</p>
  
      {{html-safe (i18n "encrypt.preferences.notice_active")}}
  
      <p>
        <label for="passphrase">
          {{i18n "encrypt.preferences.paper_key_label"}}
        </label>
        <Input
          @type="password"
          @value={{this.passphrase}}
          placeholder={{i18n "encrypt.preferences.paper_key_placeholder"}}
          id="passphrase"
          autocomplete="current-password"
          disabled={{this.inProgress}}
        />
      </p>
    </:body>
  
    <:footer>
      <DButton
        class="btn btn-primary"
        @icon="unlock"
        @label="encrypt.preferences.activate"
        @action={{this.activate}}
      />
      <DModalCancel @close={{this.close}} />
    </:footer>
  </DModal>
  */
  {
    "id": "zfS2kPal",
    "block": "[[[8,[39,0],[[24,0,\"activate-encrypt-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[28,[37,1],[\"encrypt.title\"],null],[30,0,[\"close\"]],[30,0,[\"error\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.preferences.status_enabled_but_inactive\"],null]],[13],[1,\"\\n\\n    \"],[1,[28,[35,2],[[28,[37,1],[\"encrypt.preferences.notice_active\"],null]],null]],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"passphrase\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"encrypt.preferences.paper_key_label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],[[16,\"placeholder\",[28,[37,1],[\"encrypt.preferences.paper_key_placeholder\"],null]],[24,1,\"passphrase\"],[24,\"autocomplete\",\"current-password\"],[16,\"disabled\",[30,0,[\"inProgress\"]]]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"passphrase\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn btn-primary\"]],[[\"@icon\",\"@label\",\"@action\"],[\"unlock\",\"encrypt.preferences.activate\",[30,0,[\"activate\"]]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@close\"],[[30,0,[\"close\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"d-modal\",\"i18n\",\"html-safe\",\"input\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/activate-encrypt.hbs",
    "isStrictMode": false
  });
  class ActivateEncrypt extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "encryptWidgetStore", [_service.service]))();
    #encryptWidgetStore = (() => (dt7948.i(this, "encryptWidgetStore"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "inProgress", [_tracking.tracked], function () {
      return false;
    }))();
    #inProgress = (() => (dt7948.i(this, "inProgress"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "passphrase", [_tracking.tracked]))();
    #passphrase = (() => (dt7948.i(this, "passphrase"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked]))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    async activate() {
      this.inProgress = true;
      try {
        await (0, _discourse.activateEncrypt)(this.currentUser, this.passphrase);
        this.appEvents.trigger("encrypt:status-changed");
        for (const widget of this.encryptWidgetStore.widgets) {
          widget.state.encryptState = "decrypting";
          widget.scheduleRerender();
        }
        this.encryptWidgetStore.reset();
        this.args.closeModal();
      } catch {
        this.error = _I18n.default.t("encrypt.preferences.paper_key_invalid");
      } finally {
        this.inProgress = false;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "activate", [_object.action]))();
    close() {
      for (const widget of this.encryptWidgetStore.widgets) {
        widget.state.encryptState = "error";
        widget.state.error = _I18n.default.t("encrypt.preferences.status_enabled_but_inactive");
        widget.scheduleRerender();
      }
      this.encryptWidgetStore.reset();
      this.args.closeModal();
    }
    static #_8 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
  }
  _exports.default = ActivateEncrypt;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivateEncrypt);
});