define("discourse/plugins/discourse-encrypt/discourse/initializers/encrypt-posts", ["exports", "@ember/application", "rsvp", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol"], function (_exports, _application, _rsvp, _ajax, _pluginApi, _utilities, _decorators, _I18n, _discourse, _protocol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Adds metadata extracted from the composer.
   *
   * @param {Object} metadata
   *
   * @return {Object}
   */
  function addMetadata(composer, metadata) {
    const model = composer.model;
    const currentUser = composer.currentUser;
    const now = new Date().toISOString();
    metadata.signed_by_id = currentUser.id;
    metadata.signed_by_name = currentUser.username;
    metadata.user_id = model.post ? model.post.user_id : model.user.id;
    metadata.user_name = model.post ? model.post.username : model.user.username;
    metadata.created_at = model.post ? model.post.created_at : now;
    metadata.updated_at = now;
    if (model.topic) {
      metadata.topic_id = model.topic.id;
    }
    if (model.post) {
      metadata.post_id = model.post.id;
      metadata.post_number = model.post.post_number;
    }
    return metadata;
  }
  var _default = _exports.default = {
    name: "encrypt-posts",
    initialize(container) {
      const user = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(user) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        api.modifyClassStatic("model:topic", {
          pluginId: "encrypt-posts",
          update(topic, props) {
            // TODO: https://github.com/emberjs/ember.js/issues/15291
            const {
              _super
            } = this;
            if (!(0, _discourse.hasTopicKey)(topic.id)) {
              return _super.call(this, ...arguments);
            }
            const unencryptedTitle = props.title;
            return (0, _discourse.getTopicKey)(topic.id).then(key => (0, _protocol.encrypt)(key, {
              raw: props.title
            })).then(encryptedTitle => {
              props.title = _I18n.default.t("encrypt.encrypted_title");
              props.encrypted_title = encryptedTitle;
            }).then(() => _super.call(this, ...arguments)).then(() => {
              topic.set("fancy_title", (0, _utilities.escapeExpression)(unencryptedTitle));
              topic.set("unicode_title", unencryptedTitle);
            });
          }
        });
        api.modifyClass("adapter:post", {
          pluginId: "encrypt-posts",
          createRecord(store, type, args) {
            // TODO: https://github.com/emberjs/ember.js/issues/15291
            const {
              _super
            } = this;
            if (!args.is_encrypted) {
              return _super.call(this, ...arguments);
            }
            const identityPromise = (0, _discourse.getIdentity)();
            const topicKeyPromise = args.topic_id ? (0, _discourse.getTopicKey)(args.topic_id) : (0, _protocol.generateKey)();
            const {
              title,
              raw
            } = args;
            const titlePromise = title ? topicKeyPromise.then(key => (0, _protocol.encrypt)(key, {
              raw: title
            })).then(encryptedTitle => args.encrypted_title = encryptedTitle).finally(() => args.title = _I18n.default.t("encrypt.encrypted_title")) : _rsvp.Promise.resolve();
            const replyPromise = raw ? topicKeyPromise.then(key => (0, _protocol.encrypt)(key, {
              raw
            }, {
              includeUploads: true
            })).then(encryptedRaw => args.encrypted_raw = encryptedRaw).finally(() => args.raw = _I18n.default.t("encrypt.encrypted_post")) : _rsvp.Promise.resolve();
            let encryptedKeysPromise = _rsvp.Promise.resolve();
            let usernames = [];
            if (args.target_recipients) {
              usernames = args.target_recipients.split(",");
            }
            const currentUser = (0, _application.getOwner)(this).lookup("service:current-user");
            if (usernames.length > 0) {
              usernames.push(currentUser.username);
              encryptedKeysPromise = _rsvp.Promise.all([topicKeyPromise, (0, _discourse.getUserIdentities)(usernames)]).then(_ref => {
                let [key, identities] = _ref;
                const promises = [];
                for (let i = 0; i < usernames.length; ++i) {
                  promises.push((0, _protocol.exportKey)(key, identities[usernames[i]].encryptPublic));
                }
                return _rsvp.Promise.all(promises);
              }).then(userKeys => {
                args.encrypted_keys = {};
                for (let i = 0; i < userKeys.length; ++i) {
                  args.encrypted_keys[usernames[i]] = userKeys[i];
                }
                args.encrypted_keys = JSON.stringify(args.encrypted_keys);
              }).catch(username => {
                const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
                dialog.alert(_I18n.default.t("encrypt.composer.user_has_no_key", {
                  username
                }));
                return _rsvp.Promise.reject(username);
              });
            }

            // Hide any information that might give a hint of what this message
            // may contain.
            args.composer_open_duration_msecs = 10000;
            args.typing_duration_msecs = 10000;
            return _rsvp.Promise.all([titlePromise, replyPromise, encryptedKeysPromise]).then(() => _super.call(this, ...arguments)).then(result => _rsvp.Promise.all([topicKeyPromise, titlePromise, identityPromise]).then(_ref2 => {
              let [key, encTitle, identity] = _ref2;
              (0, _discourse.putTopicKey)(result.payload.topic_id, key);
              (0, _discourse.putTopicTitle)(result.payload.topic_id, encTitle);
              return (0, _protocol.encrypt)(key, {
                raw,
                signed_by_id: currentUser.id,
                signed_by_name: currentUser.username,
                user_id: result.payload.user_id,
                user_name: result.payload.username,
                topic_id: result.payload.topic_id,
                post_id: result.payload.id,
                post_number: result.payload.post_number,
                created_at: result.payload.created_at,
                updated_at: result.payload.updated_at
              }, {
                signKey: identity.signPrivate,
                includeUploads: true
              }).then(encryptedRaw => {
                result.payload.encrypted_raw = encryptedRaw;
                return (0, _ajax.ajax)("/encrypt/post", {
                  type: "PUT",
                  data: {
                    post_id: result.payload.id,
                    encrypted_raw: encryptedRaw
                  }
                });
              });
            }).then(() => result));
          },
          update(store, type, id, attrs) {
            // TODO: https://github.com/emberjs/ember.js/issues/15291
            const {
              _super
            } = this;
            if (!(0, _discourse.hasTopicKey)(attrs.topic_id)) {
              return _super.call(this, ...arguments);
            }
            return _rsvp.Promise.all([(0, _discourse.getTopicKey)(attrs.topic_id), (0, _discourse.getIdentity)()]).then(_ref3 => {
              let [key, identity] = _ref3;
              const metadata = addMetadata((0, _application.getOwner)(this).lookup("service:composer"), {
                raw: attrs.raw
              });
              return (0, _protocol.encrypt)(key, metadata, {
                signKey: identity.signPrivate,
                includeUploads: true
              });
            }).then(encryptedRaw => {
              delete attrs.cooked;
              delete attrs.raw_old;
              attrs.raw = encryptedRaw;
            }).then(() => _super.call(this, ...arguments));
          }
        });
        api.modifyClass("controller:topic", dt7948.p({
          pluginId: "encrypt",
          _editingTopicChanged() {
            if (this.get("editingTopic")) {
              const topicId = this.get("model.id");
              (0, _discourse.getTopicTitle)(topicId).then(topicTitle => {
                // Update the title stored in buffered state
                this.buffered.set("title", topicTitle);
              });
            }
          }
        }, [["method", "_editingTopicChanged", [(0, _decorators.observes)("editingTopic")]]]));
      });
    }
  };
});